export const LEAGUE_CREATED = 'LEAGUE_CREATED'
export const LEAGUE_PLAYER_ADDED = 'LEAGUE_PLAYER_ADDED'
export const PLAYER_CREATED = 'PLAYER_CREATED'
export const PLAYER_DELETED = 'PLAYER_DELETED'
export const PLAYER_WON_MATCH = 'PLAYER_WON_MATCH'
export const PLAYER_LOST_MATCH = 'PLAYER_LOST_MATCH'
export const PLAYER_SET_DEFAULT_LEAGUE = 'PLAYER_SET_DEFAULT_LEAGUE'
export const SEASON_CREATED = 'SEASON_CREATED'
export const SEASON_ENDED = 'SEASON_ENDED'
export const SEASON_STARTED = 'SEASON_STARTED'
export const SEASON_RATING_CHANGED = 'SEASON_RATING_CHANGED'
export const SEASON_MATCH_REGISTERED = 'SEASON_MATCH_REGISTERED'
export const SINGLEMATCH_PLAYED = 'SINGLEMATCH_PLAYED'
export const DOUBLEMATCH_PLAYED = 'DOUBLEMATCH_PLAYED'
